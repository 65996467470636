<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-data-table height="62vh" fixed-header item-key="id" dense hide-default-footer disable-pagination
          :headers="headers" :items="pallets" style="cursor: pointer">
         
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="editPallet(item)"><v-icon>attach_file</v-icon></v-btn>

          </template>
          <template v-slot:[`item.line`]="{ index }">
            <span> {{ index + 1 }}</span>
          </template>

          <template v-slot:[`item.additional`]="{ item }">
            <v-tooltip top v-if="item.consignmentNote">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-1" outlined v-on="on" small icon @click="viewConsignmentNote(item)">
                  <v-icon small>description</v-icon>
                </v-btn>
              </template>
              <span>Consignment Note</span>
            </v-tooltip>

            <v-tooltip top v-if="item.permitDocument">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-1" outlined v-on="on" small icon @click="viewRemovalPermit(item)">
                  <v-icon small>agriculture</v-icon>
                </v-btn>
              </template>
              <span>Removal Permit: {{ item.permitNo }} expires
                {{ item.permitValidUntil }}</span>
            </v-tooltip>

            <!-- <v-tooltip top v-if="checkMetadata('Titan Ready', item.palletMetadata)">
              <template v-slot:activator="{ on }">
                <v-chip outlined small class="mx-1" v-on="on" @click="viewTitan(item)" :color="
                  verify('Titan Ready', item.palletMetadata) ? 'green' : 'red'
                ">
                  <v-icon v-if="verify('Titan Ready', item.palletMetadata)" small left>check</v-icon>
                  <v-icon v-else color="red" left small>close</v-icon>
                  Titan
                </v-chip>
              </template>
              <span>Titan Check:
                {{ metadataValue("Titan Ready", item.palletMetadata) }}</span>
            </v-tooltip>

            <v-tooltip top v-if="checkMetadata('Phytclean Verified', item.palletMetadata)">
              <template v-slot:activator="{ on }">
                <v-chip outlined class="mx-1" small v-on="on" @click="viewPhytoCertificate(item)" :color="
                  verify('Phytclean Verified', item.palletMetadata)
                    ? 'green'
                    : 'red'
                ">
                  <v-icon v-if="verify('Phytclean Verified', item.palletMetadata)" small left>check</v-icon>
                  <v-icon v-else color="red" left small>close</v-icon>
                  Phytclean
                </v-chip>
              </template>
              <span>Phytclean verification:
                {{
                  metadataValue("Phytclean Verified", item.palletMetadata)
                }}</span>
            </v-tooltip> -->
          </template>

          <template v-slot:[`item.farm`]="{ item }">
            <span>{{ item.farm }}</span>
          </template>

          <template v-slot:[`item.packHouse`]="{ item }">
            <span>{{ item.packHouse }}</span>
          </template>

          <template v-slot:[`item.orchard`]="{ item }">
            <span>{{ item.orchard }}</span>
          </template>

          <template v-slot:[`item.gradeClassCode`]="{ item }">
            <span>{{
              item.gradeClassCode }}</span>
          </template>

          <template v-slot:[`item.nettWeight`]="{ item }">
            <span>{{ item.nettWeight }}</span>
          </template>

          <template v-slot:[`item.inspectionDate`]="{ item }">
            <span>{{
              item.inspectionDate }}</span>
          </template>

          <template v-slot:[`item.stuffDate`]="{ item }">
            <span>{{ item.stuffDate }}</span>
          </template>

          <template v-slot:[`item.inspectionManifestNo`]="{ item }">
            <span>{{ item.inspectionManifestNo }}</span>
          </template>

          <template v-slot:[`item.inspectionPoint`]="{ item }">
            <span>{{ item.inspectionPoint }}</span>
           
          </template>
        </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['pallets'],
    data: ()=>({
        consignmentNoteModal: false,
        documentItem: {},
        headers: [
    {
        text: "Action",
        value: "action",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "Line",
        value: "line",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "Additional",
        value: "additional",
        sortable: false,
        width: "220px",
        align: "center",
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Production Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",

        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "No. of Cartons",
        value: "noCartons",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },
      {
        text: "Inspection Point",
        value: "inspectionPoint",
        sortable: false,
        align: "center",
      },
    ],
    itemKey: 1000,
    modal: false,
    pallet: {},
    palletModal: false
    }),
    methods: {
        editPallet(pallet) {
      this.pallet = pallet;
      this.palletModal = true;
    },
        metadataValue(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find.value;
    },
    checkMetadata(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? true : false;
    },
        verify(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? find.value == "Passed" : false;
    },
    async viewPhytoCertificate(item) {
      let meta = item.palletMetadata.find((x) => x.key == "Phytclean Verified");
      // let find = this.phytCleanVerification.find(
      //   (x) => x.containerPalletId == item.id && x.certificate
      // );
      this.documentItem = item;
      this.documentItem.loadingImage = true;
      this.modal = true;
      let file = await this.$API.downloadFile({
        key: meta.data,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewConsignmentNote(item) {
      this.documentItem = item.consignmentNote;
      this.documentItem.loadingImage = true;
      this.consignmentNoteModal = true;
      let file = await this.$API.downloadFile({
        key: item.consignmentNote.key,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    }

}
</script>