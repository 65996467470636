<template>
    <div>
        <v-card flat>
            <v-card-text>
                <ProductBreakdownTable :headers="summaryHeaders" :items="summaryItems" :tableKey="tableKey" :loading="loading" :height="'63vh'" />
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import ProductBreakdownTable from '../SystemComponents/ProductBreakdownTable.vue'

export default {
    props: ['shipment', 'shipmentProducts', 'scope'],
    components: {
        ProductBreakdownTable
    },
    data: ()=>({
        loading: false,
        summaryHeaders: [
            {
                text: "Product",
                value: "productName",
                align: "center",
            },
            {
                text: "Variety",
                value: "varietyName",
                align: "center",
            },
            {
                text: "Grade/Class",
                value: "gradeClassCode",
                align: "center",
            },
            {
                text: "Pack Code",
                value: "packCode",
                align: "center",
            },
            {
                text: "Count/Size",
                value: "countSize",
                align: "center",
            },
            {
        text: "Pallet Qty",
        value: "palletQuantity",
        align: "center",
      },
            {
                text: "No. Cartons",
                value: "noCartons",
                align: "center",
            },
            {
                text: "Percentage",
                value: "percentage",
                align: "center",
            },
        ],
        summaryItems: [],
        tableKey: 0,
    }),
    watch: {
        'shipment.id':{
            immediate: true,
            handler(val){
                if(val){
                    this.getShipmentProductBreakdown(val)
                }
            }
        }
    },
    methods: {
        async getShipmentProductBreakdown(id){
            this.loading= true
            this.summaryItems = await this.$API.getShipmentProductSummary(id)
            this.loading = false
        }
    }
}

</script>