<template>
  <div class="upload">
    <v-snackbar v-model="snackbar" :timeout="2000" absolute bottom color="success" center text style="z-index: 99999">
      Copied to clipboard!
    </v-snackbar>
    <v-card flat>
      <v-card-text class="my-0 py-0">
        <v-row class="mt-1" justify="center">
          <v-col cols="12" sm="4" md="3" class="mt-0 pt-0">
            <v-card flat id="draft-documents">
              <v-divider></v-divider>
              <v-toolbar dense flat>
                <v-toolbar-title style="font-size: 16px">
                  <v-icon class="mr-2">edit_note</v-icon> Draft Document Pack
                </v-toolbar-title> <v-spacer></v-spacer>
                <!-- <v-btn icon @click="share('Draft Documents')"><v-icon>share</v-icon></v-btn> -->
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-list dense style="max-height: 55vh; overflow-y: auto" class="my-0 py-0" :key="draftDocRefreshKey">
                  <v-list-item v-if="draftDocuments.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No draft documents</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="draft in draftDocuments" :key="draft.id">
                    <v-list-item-action style="width: 20px">
                      <v-img style="height: 30px; width: 30px" :src="getIcon(draft.name)">
                      </v-img>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ draft.name }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ formatDate(draft.createdAt) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="viewDocument(draft)"><v-icon>launch</v-icon></v-btn>
                          </template>
                          <span style="font-size: 12px">View Document</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="red"
                              @click="removeFromDraft(draft)"><v-icon>cancel</v-icon></v-btn>
                          </template>
                          <span style="font-size: 12px">Remove Document from Draft Pack</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card flat id="final-documents">
              <v-divider></v-divider>
              <v-toolbar dense flat>
                <v-toolbar-title style="font-size: 16px">
                  <v-icon class="mr-2">checklist</v-icon> Final Document Pack
                </v-toolbar-title> <v-spacer></v-spacer>
                <!-- <v-btn icon @click="share('Final Documents')"><v-icon>share</v-icon></v-btn> -->
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-list dense style="max-height: 60vh; overflow-y: auto" class="my-0 py-0" :key="finalDocRefreshKey">
                  <v-list-item v-if="finalDocuments.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No final documents</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="final in finalDocuments" :key="final.id">
                    <v-list-item-action style="width: 20px">
                      <v-img style="height: 30px; width: 30px" :src="getIcon(final.name)">
                      </v-img>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ final.name }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ formatDate(final.createdAt) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, }">
                            <v-btn icon v-on="on" @click="viewDocument(final)"><v-icon>launch</v-icon></v-btn>
                          </template>
                          <span style="font-size: 12px">View Document</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="red"
                              @click="removeFromFinal(final)"><v-icon>cancel</v-icon></v-btn>
                          </template>
                          <span style="font-size: 12px">Remove Document from Final Pack</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card flat id="suggested-documents">
              <v-divider></v-divider>
              <v-toolbar dense flat>
                <v-toolbar-title style="font-size: 16px">
                  Suggested Documents
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="selectedDocuments.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="white" class="mx-0 px-0"
                      :loading="savingSelectedDocuments" @click="addSelectedDocuments"><v-icon>add</v-icon></v-btn>
                  </template>
                  <span style="font-size: 12px">Add documents to shipment</span>
                </v-tooltip>
                <v-simple-checkbox class="my-0 py-0" color="primary" v-if="!loadingSuggestions && suggestions.length > 0"
                  :value="selectedDocuments.length == suggestions.length" @click="updateSelectedDocuments"
                  :indeterminate="selectedDocuments.length > 0 && selectedDocuments.length < suggestions.length">
                </v-simple-checkbox>

              </v-toolbar>
              <v-divider></v-divider>

              <v-progress-linear indeterminate color="primary" v-if="loadingSuggestions"></v-progress-linear>
              <v-list dense style="max-height: 40vh; overflow-y: auto" class="my-0 py-0">
                <v-list-item v-for="suggestion in suggestions" :key="suggestion.id">
                  <v-list-item-action style="width: 20px">
                    <v-img style="height: 30px; width: 30px" :src="getIcon(suggestion.name)">
                    </v-img>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-left" style="font-size: 14px">
                      {{ suggestion.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px" v-if="suggestion.user">
                      <v-icon small>person</v-icon>
                      {{ suggestion.user.firstname }}
                      {{ suggestion.user.surname }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-else>
                      System Generated
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-if="suggestion.shipmentDocumentClassifications &&
                      suggestion.shipmentDocumentClassifications.length >
                      0
                      ">
                      {{
                        suggestion.shipmentDocumentClassifications.length
                      }}
                      Classification<span v-if="suggestion.shipmentDocumentClassifications
                            .length > 1
                          ">s</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-if="suggestion.linkedContainers &&
                      suggestion.linkedContainers.length > 0
                      ">
                      {{ suggestion.linkedContainers.length }}
                      Container<span v-if="suggestion.linkedContainers.length > 1">s</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-if="suggestion.isFinancialDocument">
                      <v-icon small class="mr-1">account_balance</v-icon>
                      Financial Document
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      {{ formatDate(suggestion.createdAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action style="width: 85px">
                    <v-row justify="center" align="center">

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon color="white" class="mx-0 px-0"
                            @click="viewDocument(suggestion, true)"><v-icon>info</v-icon></v-btn>
                        </template>
                        <span style="font-size: 12px">View Info</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon color="white" @click="downloadDocument(suggestion)"
                            class="mx-0 px-0" :loading="suggestion.loading"><v-icon>download</v-icon></v-btn>
                        </template>
                        <span style="font-size: 12px">Download</span>
                      </v-tooltip>
                      <v-checkbox color="primary" dense v-model="selectedDocuments" :value="suggestion.id"></v-checkbox>

                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="!loadingSuggestions && suggestions.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No suggestions</span>

                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="9" class="py-0 my-0">
            <v-row justify="end">
              <v-col cols="6">
                <v-menu v-if="fileList.length > 0" v-model="menu" class="ml-3" :nudge-width="200" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text outlined style="text-transform: none">
                      <v-chip left small class="mr-2">{{
                        fileList.length
                      }}</v-chip>
                      File Upload
                      <v-icon right>arrow_drop_down</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list nav shaped dense style="height: 50%; overflow-y: auto">
                      <v-list-item v-for="(file, i) in fileList" :key="file.id">
                        <v-list-item-action>
                          <v-img style="width: 30px; height: 30px" :src="getIcon(file.file.name)">
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ file.file.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ file.file.type }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action :key="file.progress">
                          <v-btn v-if="!file.progress || file.progress == 0" icon color="red"
                            @click="fileList.splice(i, 1)">
                            <v-icon>close</v-icon>
                          </v-btn>
                          <span v-else-if="file.progress < 100">
                            {{ file.progress }}%
                          </span>
                          <span v-else-if="file.progress == 100">
                            <v-icon color="green" class="mr-1">check</v-icon>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="mt-0 pt-0 text-right">
                <v-chip style="height: 40px"
              outlined
              small>
              <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <el-button id="create-merged" v-bind="attrs" v-on="on"
                          @click="createMergedDocument()"><v-icon>picture_as_pdf</v-icon></el-button>
                      </template>
                      <span style="font-size: 12px">Create Merged Document</span>
                    </v-tooltip>
                  <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
                </v-chip>
                <!-- <el-input id="search-merged" ref="searchBar" suffix-icon="el-icon-search" v-model="search" clearable
                  placeholder="Search">
                  <template slot="prepend">
                   
                  </template>
                  <template slot="append">
                    <el-button @click="infoStartGuide">
                      <v-icon>help</v-icon>
                    </el-button>
                  </template>
                </el-input> -->
              </v-col>
            </v-row>
            <v-col cols="12" class="py-0 my-0">
              <v-card rounded flat id="upload-files">
                <v-card-text class="text-center">
                  <span v-if="fileList.length == 0" style="vertical-align: middle">
                    <v-icon class="mr-2">upload</v-icon>
                    Drop or
                    <v-btn text class="mx-1 px-0" small color="blue" @click="addFiles()">Select Files</v-btn>
                    to upload</span>

                </v-card-text>
              </v-card>
            </v-col>
            <v-card flat id="document-info">
              <v-card-text class="my-0 py-0" style="min-height: 10vh; height: 48vh; overflow-y: auto" v-loading="loading"
                :key="documentUpdateKey">
                <v-row>
                  <v-col cols="12" v-if="!loading && filterDocuments == 0" class="text-center">
                    <span style="color: grey">No documents found.</span></v-col>

                  <v-col cols="12" sm="4" md="3" v-for="(note, index) in filterDocuments" :key="note.id">
                    <v-hover v-slot:default="{ hover }">
                      <v-card style="border-radius: 20px" outlined class="pa-0 ma-0">
                        <v-expand-transition>
                          <v-card v-if="hover"
                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal display-3 white--text"
                            style="height: 60%">
                            <v-card-text class="py-0 my-0">
                              <v-row justify="space-around">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white"
                                      @click="viewDocument(note)"><v-icon>info</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">View Info</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white" @click="downloadDocument(note)"
                                      :loading="note.loading"><v-icon>download</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Download</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white"
                                      @click="deleteDocument(note, index)"><v-icon>delete</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Delete</span>
                                </v-tooltip>
                                <v-tooltip top v-if="!note.isDraftDoc">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white"
                                      @click="addDocToDraft(note)"><v-icon>library_add</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Add to Draft Document Pack</span>
                                </v-tooltip>
                                <v-tooltip top v-if="!note.isFinalDoc">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white"
                                      @click="addDocToFinal(note)"><v-icon>add_task</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Add to Final Document Pack</span>
                                </v-tooltip>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-expand-transition>
                        <v-card-text class="my-0 mx-0 px-0">
                          <v-row justify="center">
                            <v-col cols="12" class="my-0 py-0">
                              <v-list-item class="my-0 py-0">
                                <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                                  <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(note.name)">
                                  </v-img>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title class="text-left" style="font-size: 14px">
                                    {{ note.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="font-size: 12px" v-if="note.user">
                                    <v-icon small>person</v-icon>
                                    {{ note.user.firstname }}
                                    {{ note.user.surname }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px" v-else>
                                    System Generated
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px" v-if="note.shipmentDocumentClassifications &&
                                    note.shipmentDocumentClassifications
                                      .length > 0
                                    ">
                                    {{
                                      note.shipmentDocumentClassifications
                                        .length
                                    }}
                                    Classification<span v-if="note.shipmentDocumentClassifications
                                          .length > 1
                                        ">s</span>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px" v-if="note.shipmentContainerDocuments &&
                                    note.shipmentContainerDocuments.length > 0
                                    ">
                                    {{ note.shipmentContainerDocuments.length }}
                                    Container<span v-if="note.shipmentContainerDocuments.length >
                                      1
                                      ">s</span>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px" v-if="note.isFinancialDocument">
                                    <v-icon small class="mr-1">account_balance</v-icon>
                                    Financial Document
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    {{ formatDate(note.createdAt) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>


          <!-- <v-col cols="12" class="pt-1" :md="suggestions.length > 0 ? 9 : 12">
            
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>

    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <file-upload class="my-0 py-0" post-action="" :multiple="true" :drop="true" :drop-directory="true" v-model="fileList"
      ref="uploader">
    </file-upload>

    <v-dialog v-model="documentModal" persistent width="1200px" :fullscreen="$vuetify.breakpoint.mobile || fullscreen">
      <v-card v-if="documentItem">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ documentItem.name }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn id="info-guide" @click="previewStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn>
          <v-btn text color="primary" v-if="!documentItem.suggestion" @click="saveDocumentItem"
            :loading="savingDocumentItem">Save</v-btn>
          <v-btn text color="primary" v-else-if="documentItem.suggestion" @click="addDocumentItem"
            :loading="savingDocumentItem">Add to Shipment</v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon @click="downloadDocument(documentItem)"
            :loading="documentItem.loading"><v-icon>download</v-icon></v-btn>
          <v-btn text @click="
            (documentModal = false),
            (documentItem = {}),
            (savingDocumentItem = false)
            ">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="documentTab" :key="documentKey">
            <v-tab href="#classification">Classifications</v-tab>
            <v-tab href="#preview" v-if="documentItem.fileType == 'application/pdf'">Preview</v-tab>
            <v-tab href="#financialinfo" v-if="documentItem.isFinancialDocument &&
              documentItem.shipmentDocumentMetadata &&
              documentItem.shipmentDocumentMetadata.length > 0
              ">Financial Information</v-tab>
          </v-tabs>
          <v-tabs-items v-model="documentTab" :style="{
            'max-height': fullscreen ? '88vh' : '75vh',
            'overflow-y': 'auto',
            'background-color': 'var(--v-modal-base) !important',
          }">
            <v-tab-item value="classification" style="background-color: var(--v-modal-base) !important">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" lg="4">
                      <v-list dense>
                        <v-subheader style="font-size: 16px"><v-chip class="mr-1" small>{{
                          selectedDocumentTypes.length
                        }}</v-chip>
                          Classifications</v-subheader>
                        <v-text-field id="classification-search" class="my-0 py-0" outlined dense
                          v-model="searchClassification" placeholder="Search" prepend-inner-icon="search"></v-text-field>
                        <v-list id="classification-list" class="mt-0 pt-0" dense :style="{
                          'max-height': fullscreen ? '70vh' : '50vh',
                          'overflow-y': 'auto',
                          'background-color':
                            'var(--v-modal-base) !important',
                        }">
                          <v-list-item v-for="document in uniqueDocuments" :key="document.id">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ document.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox id="classification-select" v-model="selectedDocumentTypes" color="primary"
                                :value="document.id"></v-checkbox>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item v-if="uniqueDocuments.length == 0">
                            <v-list-item-content>
                              <span style="color: grey; font-size: 14px">No documents found</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-list dense>
                        <v-subheader style="font-size: 16px"><v-chip class="mr-1" small>{{
                          selectedSupportingDocuments.length
                        }}</v-chip>
                          Supporting Documents
                        </v-subheader>
                        <v-text-field id="supporting-doc-search" class="my-0 py-0" outlined dense
                          v-model="searchClassification" placeholder="Search" prepend-inner-icon="search"></v-text-field>
                        <v-list id="supporting-doc-list" class="mt-0 pt-0" dense :style="{
                          'max-height': fullscreen ? '70vh' : '50vh',
                          'overflow-y': 'auto',
                          'background-color':
                            'var(--v-modal-base) !important',
                        }">
                          <v-list-item v-for="document in uniqueSupportingDocuments" :key="document.id">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ document.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox id="supporting-doc-select" v-model="selectedSupportingDocuments" color="primary"
                                :value="document.id"></v-checkbox>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item v-if="uniqueSupportingDocuments.length == 0">
                            <v-list-item-content>
                              <span style="color: grey; font-size: 14px">No supporting documents found</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-list dense>
                        <v-subheader style="font-size: 16px"><v-chip class="mr-1" small v-if="!allContainers">{{
                          selectedContainers.length
                        }}</v-chip>
                          <v-chip class="mr-1" small v-else>{{
                            bookings.length
                          }}</v-chip>
                          Linked Containers
                          <v-spacer></v-spacer>
                          <v-switch v-model="allContainers" label="All" dense small></v-switch>
                        </v-subheader>
                        <v-text-field id="linked-search" class="my-0 py-0" outlined dense v-model="searchContainers"
                          placeholder="Search" prepend-inner-icon="search"></v-text-field>
                        <v-list id="linked-list" class="mt-0 pt-0" dense :style="{
                          'max-height': fullscreen ? '70vh' : '50vh',
                          'overflow-y': 'auto',
                          'background-color':
                            'var(--v-modal-base) !important',
                        }">
                          <v-list-item v-for="container in filterContainers" :key="container.id">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ container.containerNo }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                v-if="documentItem.linkedContainers && documentItem.linkedContainers.includes(container.containerNo)">
                                <i style="color: green">Suggested</i>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox id="linked-select" v-if="!allContainers" v-model="selectedContainers"
                                color="primary" :value="container.id"></v-checkbox>
                              <v-icon v-else color="success">check</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item v-if="filterContainers.length == 0">
                            <v-list-item-content>
                              <span style="color: grey; font-size: 14px">No containers found</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="preview">
              <v-row justify="center">
                <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
                  <!-- <v-btn @click="annotate">Annotate</v-btn> -->
                  <div id="pdf-component" ref="pdfBox">
                    <pdf ref="myPdfComponent" :src="documentItem.file" v-for="page in documentItem.pages" :key="page"
                      :page="page"></pdf>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="financialinfo">
              <v-card flat>
                <v-card-text>
                  <v-list dense style="background-color: transparent !important" v-if="documentItem.shipmentDocumentMetadata &&
                    documentItem.shipmentDocumentMetadata.length > 0
                    ">
                    <v-row>
                      <v-col cols="12" sm="6" class="my-0 py-0" v-for="meta in documentItem.shipmentDocumentMetadata.filter(
                            (x) => x.type != 'OTHER'
                          )" :key="meta.id">
                        <v-list-item style="background: transparent !important">
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">{{
                              meta.value
                            }}</v-list-item-title>

                            <v-list-item-subtitle>{{
                              meta.key
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="6" class="my-0 py-0" v-for="meta in documentItem.shipmentDocumentMetadata.filter(
                            (x) => x.type == 'OTHER'
                          )" :key="meta.id">
                        <v-list-item style="background-color: transparent !important">
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">{{
                              meta.value
                            }}</v-list-item-title>

                            <v-list-item-subtitle>{{
                              meta.key
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="mergeModal" persistent width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Merge Documents</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn id="info-guide" @click="mergeStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn>
          <v-btn text @click="mergeModal = false, mergedDocument = {}">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="mergedDocument.documents" style="min-height: 50vh; max-height: 75vh; overflow-y: auto">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field id="document-name" label="Document Name*" v-model="mergedDocument.name" suffix=".pdf" outlined
                dense></v-text-field>

            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn id="submit-merge" @click="mergeDocuments" :loading="loadingMerge"
                :disabled="!mergedDocument.name || !mergedDocument.documents || mergedDocument.documents.length == 0"
                color="primary" text>Submit Merge</v-btn>
            </v-col>

            <v-col cols="12" sm="6" v-if="!loadingMerge">
              <v-list dense subheader id="available-list">
                <v-subheader>Available Documents</v-subheader>
                <v-list-item v-for="available in availableMergeDocuments" :key="available.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ available.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ available.pages }} Page(s)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn id="select-doc" icon color="blue"
                      @click="addMergeDocument(available)"><v-icon>add_circle_outline</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" v-if="!loadingMerge">
              <v-list dense subheader id="selected-list">
                <v-subheader>Selected Documents</v-subheader>
                <v-list-item v-for="(document, index) in mergedDocument.documents" :key="document.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ document.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ document.pages }} Page(s)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn id="remove-doc" icon color="red"
                      @click="removeMergedDocument(index)"><v-icon>remove_circle_outline</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="mergedDocument.documents.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents selected</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="shareItemModal" width="600px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="shareItem">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Share {{ shareItem.type }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shareItemModal = false, shareItem = {}">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="shareItem.link" outlined readonly dense append-outer-icon="content_copy"
            @click:append-outer="copyContent"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Uploaded Documents!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewStart" max-width="31vw">
      <v-card>
        <v-btn @click="previewStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Uploaded Documents - Info!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startPreviewGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mergeStart" max-width="31vw">
      <v-card>
        <v-btn @click="mergeStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Uploaded Documents - Merge Documents!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startMergeGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import dateFormat from "dateformat";
import FileUpload from "vue-upload-component";
import pdf from "vue-pdf";

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from './Steps/documentUploadSteps'
// import previewSteps from './Steps/documentUploadPreviewSteps'
// import mergeSteps from './Steps/documentUploadMergeSteps'

export default {
  props: [
    "item",
    "bookings",
    "documents",
    "documentTypes",
    "documentUpdateKey",
  ],
  components: {
    FileUpload,
    pdf,
    // VueDocPreview
  },
  data: () => ({
    steps: null,
    previewSteps: null,
    mergeSteps: null,
    allContainers: false,
    documentKey: 0,
    documentItem: {},
    documentModal: false,
    documentTab: "classification",
    draftDocRefreshKey: 1000,
    finalDocRefreshKey: 2000,
    fileList: [],
    fullscreen: false,
    loading: false,
    loadingSuggestions: false,
    loadingMerge: false,
    mergedDocument: {},
    mergeModal: false,
    menu: false,
    savingDocumentItem: false,
    savingSelectedDocuments: false,
    search: null,
    searchClassification: null,
    searchContainers: null,
    selectedDocumentTypes: [],
    selectedDocuments: [],
    selectedContainers: [],
    selectedSupportingDocuments: [],
    shareItem: {},
    shareItemModal: false,
    snackbar: false,
    suggestions: [],
    timeout: null,
    uploadingFile: false,
    infoStart: false,
    previewStart: false,
    mergeStart: false,
  }),
  watch: {
    "item.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getPossibleLinkedDocuments();
        }
      },
    },
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
  },

  computed: {
    availableMergeDocuments() {
      let result = this.documents.filter(x => x.type == 'application/pdf')
      if (this.mergedDocument && this.mergedDocument.documents) {
        result = result.filter(x => !this.mergedDocument.documents.map(y => y.id).includes(x.id))
      }
      return result
    },
    draftDocuments() {
      let result = this.documents.filter(x => x.isDraftDoc)
      return result
    },
    finalDocuments() {
      let result = this.documents.filter(x => x.isFinalDoc)
      return result
    },
    filterContainers() {
      let result = this.bookings;
      if (this.searchContainers) {
        result = result.filter((doc) =>
          doc.containerNo
            .toLowerCase()
            .includes(this.searchContainers.toLowerCase())
        );
      }
      return result;
    },
    filterDocuments() {
      let result = this.documents;
      if (this.search) {
        result = result.filter((doc) =>
          doc.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
    uniqueDocuments() {
      let result = [];
      let uniqueDocuments = [
        ...new Set(this.documentTypes.map((doc) => doc.locationDocument.id)),
      ];
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let find = this.documentTypes.find(
          (doc) => doc.locationDocument.id == uniqueDocuments[i]
        ).locationDocument;
        result.push(find);
      }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      if (this.searchClassification) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchClassification.toLowerCase())
        );
      }
      return result;
    },
    uniqueSupportingDocuments() {
      let result = [];
      let uniqueDocuments = this.documentTypes.filter(
        (x) =>
          x.shipmentDocumentTypeSupportings.length > 0 ||
          x.variations.some((y) => y.shipmentDocumentTypeSupportings.length > 0)
      );
      let variationDocuments = uniqueDocuments
        .map((x) => x.variations.map((y) => y.shipmentDocumentTypeSupportings))
        .flat();
      let supportingDocuments = uniqueDocuments
        .map((x) => x.shipmentDocumentTypeSupportings)
        .flat();
      let allSupportingDocuments = [
        ...supportingDocuments,
        ...variationDocuments,
      ].flat();
      let uniqueSupportingDocuments = [
        ...new Set(
          allSupportingDocuments.map((doc) => doc.supportingDocumentId)
        ),
      ];
      for (let i = 0; i < uniqueSupportingDocuments.length; i++) {
        let find = allSupportingDocuments.find(
          (doc) => doc.supportingDocumentId == uniqueSupportingDocuments[i]
        ).supportingDocument;
        result.push(find);
      }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
  },
  async created() {
    // this.getConsignmentNotes();
    await this.getSteps();
  },
  async mounted() {
    this.driver = new Driver({
      animate: false
    })
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ShipmentSummary/DocumentUpload/Steps',
        });
        this.steps = steps.steps
        let mergeSteps = await this.$API.getGuideSteps({
            guideId: 'ShipmentSummary/DocumentUpload/MergeSteps',
        });
        this.mergeSteps = mergeSteps.steps
        let previewSteps = await this.$API.getGuideSteps({
            guideId: 'ShipmentSummary/DocumentUpload/PreviewSteps',
        });
        this.previewSteps = previewSteps.steps
        },
    async addDocToDraft(doc) {
      if (doc.isDraftDoc == null) {
        doc.isDraftDoc = false
      }
      doc.isDraftDoc = !doc.isDraftDoc
      this.draftDocRefreshKey++
      await this.$API.updateShipmentDocument(doc)
    },
    async addDocToFinal(doc) {
      doc.isFinalDoc = !doc.isFinalDoc
      if (doc.isFinalDoc == null) {
        doc.isFinalDoc = false
      }
      this.finalDocRefreshKey++

      await this.$API.updateShipmentDocument(doc)
    },
    async addDocumentItem() {
      this.savingDocumentItem = true;
      delete this.documentItem.id;
      this.documentItem.shipmentId = this.item.id;
      if (this.documentItem.shipmentDocumentClassifications) {
        this.documentItem.shipmentDocumentClassifications.forEach(x => {
          delete x.id
          delete x.shipmentDocumentId
        })
      } else {
        this.documentItem.shipmentDocumentClassifications = []
      }

      this.documentItem.shipmentContainerDocuments = []
      if (this.documentItem.shipmentDocumentMetadata) {
        this.documentItem.shipmentDocumentMetadata.forEach(x => {
          delete x.id
          delete x.shipmentDocumentId
        })
      } else {
        this.documentItem.shipmentDocumentMetadata = []
      }

      this.documentItem = await this.$API.copyShipmentDocument(this.documentItem);
      this.documentItem.shipmentContainerDocuments = []
      this.documentItem.shipmentDocumentClassifications = []
      this.saveDocumentItem();
      this.$emit("getShipmentDocuments");
    },
    async addSelectedDocuments() {
      this.savingSelectedDocuments = true;

      for (let i = 0; i < this.selectedDocuments.length; i++) {
        this.documentItem = this.suggestions.find(x => x.id == this.selectedDocuments[i])
        this.documentItem.suggestion = true;
        this.selectedDocumentTypes =
          this.documentItem.shipmentDocumentClassifications
            .filter((x) => x.locationDocumentId)
            .map((x) => x.locationDocumentId);
        this.selectedSupportingDocuments =
          this.documentItem.shipmentDocumentClassifications
            .filter((x) => x.supportingDocumentId)
            .map((x) => x.supportingDocumentId);
        this.selectedContainers =
          this.documentItem.shipmentContainerDocuments.map(
            (x) => x.bookingContainerId
          );
        this.allContainers =
          this.selectedContainers.length == this.bookings.length;
        if (this.documentItem.isFinancialDocument) {
          this.documentItem.shipmentDocumentMetadata =
            await this.$API.getShipmentDocumentMetadata(this.documentItem.id);
          this.documentItem.shipmentDocumentMetadata =
            this.documentItem.shipmentDocumentMetadata.filter(
              (x) => x.type != "OTHER"
            );
        }
        this.selectedContainers = []
        delete this.documentItem.id;
        this.documentItem.shipmentId = this.item.id;
        if (this.documentItem.shipmentDocumentClassifications) {
          this.documentItem.shipmentDocumentClassifications.forEach(x => {
            delete x.id
            delete x.shipmentDocumentId
          })
        } else {
          this.documentItem.shipmentDocumentClassifications = []
        }

        this.documentItem.shipmentContainerDocuments = []
        if (this.documentItem.shipmentDocumentMetadata) {
          this.documentItem.shipmentDocumentMetadata.forEach(x => {
            delete x.id
            delete x.shipmentDocumentId
          })
        } else {
          this.documentItem.shipmentDocumentMetadata = []
        }

        this.documentItem = await this.$API.copyShipmentDocument(this.documentItem);
        this.documentItem.shipmentContainerDocuments = []
        this.documentItem.shipmentDocumentClassifications = []
        this.saveDocumentItem();
        this.$emit("getShipmentDocuments");
      }
      this.getPossibleLinkedDocuments()
      this.savingSelectedDocuments = false
      this.selectedDocuments = []

    },
    copyContent() {
      navigator.clipboard.writeText(this.shareItem.link);
      this.snackbar = true;
    },
    updateSelectedDocuments() {
      if (this.selectedDocuments.length == 0) {
        this.selectedDocuments = this.suggestions.map(x => x.id)
      } else {
        this.selectedDocuments = []
      }
    },
    addMergeDocument(document) {
      this.mergedDocument.documents.push(document)
    },
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    annotate() {
      let box = {
        Polygon: [
          { X: 0.12668897211551666, Y: 0.256186842918396 },
          { X: 0.2155465930700302, Y: 0.256186842918396 },
          { X: 0.2155465930700302, Y: 0.26168254017829895 },
          { X: 0.12668897211551666, Y: 0.26168254017829895 },
        ],
        BoundingBox: {
          Top: 0.256186842918396,
          Left: 0.12668897211551666,
          Width: 0.08885761350393295,
          Height: 0.005495687015354633,
        },
      };
      let width = this.$refs.pdfBox.clientWidth;
      let height = this.$refs.pdfBox.clientHeight;
      let boundingBox = {
        Top: 0.256186842918396,
        Left: 0.12668897211551666,
        Width: 0.08885761350393295,
        Height: 0.005495687015354633,
      };
      var canvas = this.$refs.myPdfComponent[0].$refs.canvas;
      var ctx = canvas.getContext("2d");
      ctx.strokeStyle = "red";
      ctx.beginPath();
      ctx.rect(
        boundingBox.Left * width,
        boundingBox.Top * height,
        width * boundingBox.Width,
        height * boundingBox.Height
      );
      ctx.stroke();
    },
    createMergedDocument() {
      console.log('this item', this.item)
      this.mergedDocument = {
        shipmentId: this.item.id,
        name: null,
        documents: []
      }
      this.mergeModal = true
    },
    async mergeDocuments() {
      this.loadingMerge = true
      this.mergedDocument.documents = this.mergedDocument.documents.map(x => ({ id: x.id, key: x.key }))
      let result = await this.$API.mergeShipmentDocuments(this.mergedDocument)
      this.$emit("getShipmentDocuments");
      this.mergeModal = false
      this.mergedDocument = {}
      this.loadingMerge = false
      this.$message({
        type: "success",
        message: "Successfully merged!",
      });

    },
    removeMergedDocument(index) {
      this.mergedDocument.documents.splice(index, 1)
    },
    deleteDocument(item) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          // this.loading = true
          await this.$API.updateShipmentDocument({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          let index = this.documents.findIndex(x => x.id == item.id)
          this.documents.splice(index, 1);
          this.documentKey++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async downloadDocument(doc) {
      doc.loading = true;
      this.documentKey++;
      if (!doc.file && !doc.fileType) {
        let file = await this.$API.downloadFile({
          key: doc.key,
        });
        doc.file = file.file;
        doc.fileType = file.fileType;
      }
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      doc.loading = false;
      this.documentKey++;
    },
    async fetchDocument(doc) {
      this.documentKey++;
      let file = await this.$API.downloadFile({
        key: doc.key,
      });
      doc.file = file.file;
      doc.fileType = file.fileType;
      this.documentKey++;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getPossibleLinkedDocuments() {
      this.loadingSuggestions = true;
      this.suggestions = await this.$API.getShipmentLinkedDocuments(
        this.item.id
      );
      this.loadingSuggestions = false;
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1).toLowerCase();
      }
      if (result && result.toLowerCase() == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    async processContainerClassifications(item) {
      let existingClassifications = item.shipmentDocumentClassifications
        .filter((x) => x.locationDocumentId)
        .map((x) => x.locationDocumentId);

      let add = this.selectedDocumentTypes.filter(
        (x) => !existingClassifications.includes(x)
      );
      let remove = existingClassifications.filter(
        (x) => !this.selectedDocumentTypes.includes(x)
      );
      for (let i = 0; i < add.length; i++) {
        let classification = {
          shipmentDocumentId: item.id,
          locationDocumentId: add[i],
        };
        let result = await this.$API.createShipmentDocumentClassification(
          classification
        );
        item.shipmentDocumentClassifications.push(result);
      }
      for (let i = 0; i < remove.length; i++) {
        let classification = item.shipmentDocumentClassifications.find(
          (x) => x.locationDocumentId == remove[i]
        );
        await this.$API.updateShipmentDocumentClassification({
          id: classification.id,
          isActive: false,
          isDeleted: true,
        });
        let index = item.shipmentDocumentClassifications.findIndex(
          (x) => x.id == classification.id
        );
        item.shipmentDocumentClassifications.splice(index, 1);
      }
    },
    async processContainerSupportingDocuments(item) {
      let existingSupportingDocs = item.shipmentDocumentClassifications
        .filter((x) => x.supportingDocumentId)
        .map((x) => x.supportingDocumentId);

      let add = this.selectedSupportingDocuments.filter(
        (x) => !existingSupportingDocs.includes(x)
      );
      let remove = existingSupportingDocs.filter(
        (x) => !this.selectedSupportingDocuments.includes(x)
      );
      for (let i = 0; i < add.length; i++) {
        let classification = {
          shipmentDocumentId: item.id,
          supportingDocumentId: add[i],
        };
        let result = await this.$API.createShipmentDocumentClassification(
          classification
        );
        item.shipmentDocumentClassifications.push(result);
      }
      for (let i = 0; i < remove.length; i++) {
        let classification = item.shipmentDocumentClassifications.find(
          (x) => x.supportingDocumentId == remove[i]
        );
        await this.$API.updateShipmentDocumentClassification({
          id: classification.id,
          isActive: false,
          isDeleted: true,
        });
        let index = item.shipmentDocumentClassifications.findIndex(
          (x) => x.id == classification.id
        );
        item.shipmentDocumentClassifications.splice(index, 1);
      }
    },
    async processContainerDocuments(item) {
      let existingSupportingDocs = item.shipmentContainerDocuments
        .filter((x) => x.bookingContainerId)
        .map((x) => x.bookingContainerId);
      if (this.allContainers) {
        this.selectedContainers = this.bookings.map((x) => x.id);
      }

      let add = this.selectedContainers.filter(
        (x) => !existingSupportingDocs.includes(x)
      );
      let remove = existingSupportingDocs.filter(
        (x) => !this.selectedContainers.includes(x)
      );
      for (let i = 0; i < add.length; i++) {
        let classification = {
          shipmentDocumentId: item.id,
          bookingContainerId: add[i],
        };
        let result = await this.$API.createShipmentContainerDocument(
          classification
        );
        item.shipmentContainerDocuments.push(result);
      }
      for (let i = 0; i < remove.length; i++) {
        let classification = item.shipmentContainerDocuments.find(
          (x) => x.bookingContainerId == remove[i]
        );
        await this.$API.updateShipmentContainerDocuments({
          id: classification.id,
          isActive: false,
          isDeleted: true,
        });
        let index = item.shipmentContainerDocuments.findIndex(
          (x) => x.id == classification.id
        );
        item.shipmentContainerDocuments.splice(index, 1);
      }
    },
    async processDocumentSelectedTypes(item, allContainers) {
      let containers = this.selectedContainers;
      // console.log("All documents", item);
      let findDocuments = this.documentTypes.filter(
        (x) =>
          // Container / Shipment filter
          (allContainers
            ? true
            : containers.includes(x.bookingContainerId) ||
            (x.shipmentDocumentTypeContainers &&
              x.shipmentDocumentTypeContainers.some((y) =>
                containers.includes(y.bookingContainerId)
              ))) &&
          // Document type filter
          (this.selectedDocumentTypes.includes(x.locationDocumentId) ||
            x.variations.some((y) =>
              this.selectedDocumentTypes.includes(y.locationDocumentId)
            ) ||
            x.shipmentDocumentTypeSupportings.some((y) =>
              this.selectedSupportingDocuments.includes(y.supportingDocumentId)
            ) ||
            x.variations.some((y) =>
              y.shipmentDocumentTypeSupportings.some((z) =>
                this.selectedSupportingDocuments.includes(
                  z.supportingDocumentId
                )
              )
            ))
      );
      // console.log("Possible Documents", findDocuments);

      let references = item.shipmentDocumentMetadata.filter(x => x.type == 'DOCUMENT_NO')

      let supportingDocs = [];
      let classifications = [];
      for (let i = 0; i < findDocuments.length; i++) {
        let document = findDocuments[i];

        if (
          this.selectedDocumentTypes.includes(
            findDocuments[i].locationDocumentId
          )
        ) {
          if (references.length == 1) {
            if (!document.reference) {
              document.reference = references[0].value
              await this.$API.updateShipmentDocumentType({ id: document.id, reference: references[0].value })
            }

          }
          let findClassifications = document.shipmentDocumentTypeDocuments.find(
            (x) =>
              x.shipmentDocumentId == item.id &&
              x.shipmentDocumentTypeId == document.id
          );
          if (!findClassifications) {
            let classification = {
              shipmentDocumentId: item.id,
              shipmentDocumentTypeId: document.id,
            };
            classifications.push(classification);
          }
        }

        if (
          document.shipmentDocumentTypeSupportings.some((y) =>
            this.selectedSupportingDocuments.includes(y.supportingDocumentId)
          ) ||
          document.variations.some((y) =>
            y.shipmentDocumentTypeSupportings.some((z) =>
              this.selectedSupportingDocuments.includes(z.supportingDocumentId)
            )
          )
        ) {
          let parentCheck = document.shipmentDocumentTypeSupportings.some((y) =>
            this.selectedSupportingDocuments.includes(y.supportingDocumentId)
          );
          let childCheck = document.variations.filter((x) =>
            x.shipmentDocumentTypeSupportings.some((y) =>
              this.selectedSupportingDocuments.includes(y.supportingDocumentId)
            )
          );
          if (parentCheck) {
            let existing = document.shipmentDocumentTypeSupportings.filter(
              (x) =>
                this.selectedSupportingDocuments.includes(
                  x.supportingDocumentId
                )
            );
            let newSupportingDocs = this.selectedSupportingDocuments.filter(
              (x) => !existing.some((y) => y.supportingDocumentId == x)
            );
            newSupportingDocs = newSupportingDocs.map((x) => ({
              shipmentDocumentId: item.id,
              shipmentDocumentTypeId: document.id,
              supportingDocumentId: x,
            }));
            supportingDocs = [...supportingDocs, ...newSupportingDocs];
          }

          if (childCheck.length > 0) {
            for (let j = 0; j < childCheck.length; j++) {
              let newSupportingDocs = childCheck[
                j
              ].shipmentDocumentTypeSupportings.filter(
                (x) =>
                  this.selectedSupportingDocuments.includes(
                    x.supportingDocumentId
                  ) && x.shipmentDocumentId != item.id
              );
              newSupportingDocs.forEach(
                (x) => (x.shipmentDocumentId = item.id)
              );
              newSupportingDocs = newSupportingDocs.map((x) => ({
                shipmentDocumentId: item.id,
                id: x.id,
              }));
              supportingDocs = [...supportingDocs, ...newSupportingDocs];
            }
          }
        }
      }

      if (classifications.length > 0) {
        let result = await this.$API.createShipmentTypeDocument({
          batch: classifications,
        });
        item.shipmentDocumentTypeDocuments = [
          ...item.shipmentDocumentTypeDocuments,
          ...result,
        ];
      }
      // console.log("New supporting docs", supportingDocs);
      if (supportingDocs.length > 0) {
        let result = await this.$API.createShipmentTypeSupportingDocument({
          batch: supportingDocs,
        });
        // for(let i=0;i<result.length;i++){
        //   let find = findDocuments.find(x=>x.id == result[i].shipmentDocumentTypeId)
        //   let findSupportingParent = find.shipmentDocumentTypeSupportings.find(x=>x.supportingDocumentId == result[i].supportingDocumentId)
        //   find.shipmentDocumentTypeSupportings.push(result[i])
        // }
        // item.shipmentDocumentTypeSupportings =  [...item.shipmentDocumentTypeSupportings, ...result];
        // console.log(result)
      }
    },
    async saveDocumentItem() {
      this.savingDocumentItem = true;
      await this.processContainerClassifications(this.documentItem);
      await this.processContainerSupportingDocuments(this.documentItem);
      await this.processContainerDocuments(this.documentItem);
      await this.processDocumentSelectedTypes(
        this.documentItem,
        this.allContainers
      );
      await this.processShipmentDocumentType(this.documentItem)
      this.documentModal = false;
      this.documentItem = {};
      this.savingDocumentItem = false;
    },
    async processShipmentDocumentType(documentItem){     
      let locationDocumentId = this.selectedDocumentTypes[0]
      let shipmentId = documentItem.shipmentId
      if(locationDocumentId == 82 || locationDocumentId == 217 && shipmentId) {
        let obj = {
          shipmentId: shipmentId,
          locationDocumentId: locationDocumentId,
        }
        let shipmentDocumentTypeId = await this.$API.getShipmentDoucmentTypeByShipment(obj)
        await this.$API.updateShipmentDocumentType({ id: shipmentDocumentTypeId.id, status: 'Complete', submittedDate: new Date(), completedDate: new Date() })
      }

    },
    async share(type) {
      let item = {
        type: type,
        shipmentId: this.item.id,
      }
      this.shareItem = await this.$API.shareShipment(item)
      this.shareItemModal = true
    },
    async submitFiles() {
      let pending = this.fileList.filter((x) => !x.loading);
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.item.id,
          };
          this.submitUpload(obj);
        });
      }
    },
    async submitUpload(obj) {
      let Api = axios.create({
        // baseURL: "http://localhost:3000",
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      // obj.loading = true;
      // let findIndex = this.fileList.findIndex(
      //   (x) => x.fileIndex == obj.fileIndex
      // );
      let result = await Api.post("/upload/document", obj, {
        onUploadProgress: (progressEvent) => {
          this.fileList[obj.fileIndex].progress = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          //   if(this.fileList[obj.fileIndex].progress == 100){
          //     this.fileList.splice(findIndex, 1);
          // }
        },

      });
      result = result.data;

      // this.$emit("addDocument", result);
      this.$emit('getShipmentDocuments')
      this.fileList = [];
      this.documentKey++;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async removeFromDraft(document) {
      document.isDraftDoc = false
      this.draftDocRefreshKey++
      await this.$API.updateShipmentDocument(document)
    },
    async removeFromFinal(document) {
      document.isFinalDoc = false
      this.finalDocRefreshKey++
      await this.$API.updateShipmentDocument(document)
    },
    async viewDocument(item, suggestion = false) {
      this.documentItem = item;
      this.documentItem.suggestion = suggestion;
      if (!this.documentItem.file && !this.documentItem.fileType) {
        this.fetchDocument(this.documentItem);
      }
      this.selectedDocumentTypes =
        this.documentItem.shipmentDocumentClassifications
          .filter((x) => x.locationDocumentId)
          .map((x) => x.locationDocumentId);
      this.selectedSupportingDocuments =
        this.documentItem.shipmentDocumentClassifications
          .filter((x) => x.supportingDocumentId)
          .map((x) => x.supportingDocumentId);
      this.selectedContainers =
        this.documentItem.shipmentContainerDocuments.map(
          (x) => x.bookingContainerId
        );
      this.allContainers =
        this.selectedContainers.length == this.bookings.length;
      if (this.documentItem.isFinancialDocument) {
        this.documentItem.shipmentDocumentMetadata =
          await this.$API.getShipmentDocumentMetadata(this.documentItem.id);
        this.documentItem.shipmentDocumentMetadata =
          this.documentItem.shipmentDocumentMetadata.filter(
            (x) => x.type != "OTHER"
          );
      }
      if (suggestion) {
        this.selectedContainers = []
      }
      this.documentTab = "classification"
      this.documentModal = true
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.steps)
      this.driver.start()
    },
    previewStartGuide() {
      if (this.previewStart) {
        this.previewStart = false;
      } else {
        this.previewStart = true;
      }
    },
    startPreviewGuide(e) {
      this.previewStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.previewSteps)
      this.driver.start()
    },
    mergeStartGuide() {
      if (this.mergeStart) {
        this.mergeStart = false;
      } else {
        this.mergeStart = true;
      }
    },
    startMergeGuide(e) {
      this.mergeStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.mergeSteps)
      this.driver.start()
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  border-radius: 20px;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  z-index: 9999;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
</style>
