<template>
  <div class="my-0 py-0">
    <v-card v-if="shipment && shipment.shipmentFile" flat class="mb-0 pb-0">
      <v-card-text >
          <v-row align="center" justify="space-between">
            <v-tooltip top v-for="relationship in relationships" :key="relationship.id">
            <template v-slot:activator="{ on }">
              <v-chip
                small
                class="primaryText--text"
                v-on="on"
                outlined
                style="border: none"
              >
                <v-icon
                  class="mr-2"
                  small
                  left
                  v-if="relationship.organisationRelationship.clientStatus"
                  :color="getClientStatusColor(relationship.organisationRelationship.clientStatus)"
                  >fiber_manual_record</v-icon
                >
                <v-avatar
                  size="20"
                  :color="
                    relationship.customer && relationship.customer.logo
                      ? 'white'
                      : 'secondary'
                  "
                  left
                >
                  <v-img
                    v-if="relationship.customer.logo"
                    :src="relationship.customer.logo"
                    contain
                  ></v-img>
                  <h3 v-else style="color: white">
                    {{ relationship.customer.name.charAt(0) }}
                  </h3>
                </v-avatar>
                <span v-if="relationship.customer.alias">{{
                  relationship.customer.alias
                }}</span>
                <span v-else>{{ relationship.customer.name }}</span>
              </v-chip>
            </template>
            <span
              >Customer
              <span v-if="relationship.organisationRelationship.clientStatus">
                - Status: {{ relationship.organisationRelationship.clientStatus }}</span
              ></span
            >
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">outbound</v-icon>
                <span v-if="shipment.shipper"
                  ><span v-if="shipment.shipper.alias">{{
                    shipment.shipper.alias
                  }}</span>
                  <span v-else>{{ shipment.shipper.name }}</span></span
                >
                <span v-else>No Shipper</span>
              </v-chip>
            </template>
            <span>Shipper</span>
          </v-tooltip>
          <v-tooltip bottom v-if="shipment.shipper && shipment.onBehalfShipper">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">outbound</v-icon>
                o.b.o
                <span v-if="shipment.onBehalfShipper" class="ml-1"
                  ><span v-if="shipment.onBehalfShipper.alias">{{
                    shipment.onBehalfShipper.alias
                  }}</span>
                  <span v-else class="ml-1">{{ shipment.onBehalfShipper.name }}</span></span
                >
              </v-chip>
            </template>
            <span>On behalf of Shipper</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">call_received</v-icon>
                <span v-if="shipment.consignee">
                  <span v-if="shipment.consignee.alias">{{
                    shipment.consignee.alias
                  }}</span>
                  <span v-else>{{ shipment.consignee.name }}</span>
                </span>
                <span v-else>No Consignee</span>
              </v-chip>
            </template>
            <span>Consignee</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="shipment.incoTerm ? 'secondary' : 'grey'"
                  >swap_horiz</v-icon
                >
                <span v-if="shipment.incoTerm">{{ shipment.incoTerm.incoTerm }}</span>
                <span v-else>No Inco Term</span>
              </v-chip>
            </template>
            <span>Inco Term</span>
          </v-tooltip>

          <v-tooltip bottom v-if="shipment.shippingLine">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" @click="editVoyage()">
                <v-avatar
                  left
                  color="white"
                  size="48"
                  v-if="shipment.shippingLine"
                >
                  <v-img
                    v-if="shipment.shippingLine && shipment.shippingLine.logo"
                    :src="shipment.shippingLine.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-icon left v-else :color="'grey'">directions_boat</v-icon>
                <span v-if="shipment.shippingLine"
                  ><span v-if="shipment.shippingLine.friendlyName">{{ shipment.shippingLine.friendlyName }}: </span>
                  <span v-else>{{ shipment.shippingLine.name }}: </span>
                  </span
                >
                <span v-if="shipment.vessel" class="ml-1"
                  >{{ shipment.vessel.name }}
                  {{ shipment.voyage }}</span
                >
                <span v-else>No Vessel</span>
                <span v-if="shipment.vessel.imoNumber" class="ml-1" style="color: grey">{{ shipment.vessel.imoNumber }}</span>
              </v-chip>
            </template>
            <span>Vessel</span>
          </v-tooltip>

          <v-chip outlined style="border: none">
            <v-chip :color="shipment.isATD?'success':''" outlined  style="border: none">
            <v-icon small left>schedule</v-icon>
            <span v-if="shipment.isATD" style="font-size: 12px;">ATD: {{
                  formatDate(shipment.etd) }}</span>
                <span v-else style="font-size: 12px;">ETD: {{ formatDate(shipment.etd)
                }}</span>
          </v-chip>
          <v-icon small class="mx-1">arrow_forward</v-icon>
          <v-chip :color="shipment.isATA?'success':''" outlined style="border: none">
            <v-icon small left >schedule</v-icon>
            <span v-if="shipment.isATA" style="font-size: 12px;">ATA: {{
                  formatDate(shipment.eta) }}</span>
                <span v-else style="font-size: 12px;">ETA: {{ formatDate(shipment.eta)
                }}</span>
          </v-chip>

          <v-btn @click="viewStackDates()" small text rounded class="my-0 py-0" style="text-transform: none" v-if="shipment.voyageInfo && shipment.voyageInfo.rmsStack" color="blue"><v-icon small left>view_module</v-icon> Stack Dates</v-btn>

          </v-chip>
         
                

          <!-- <v-chip
            v-if="!shipment.breakBulkShipment"
            outlined
            style="border: none"
          >
            <v-icon left :color="'grey'">widgets</v-icon>
            {{ countContainers() }} Containers
          </v-chip>
          <v-chip
            v-else
            outlined
            style="border: none"
          >
            <v-icon left :color="'grey'">widgets</v-icon>
            {{ summaryCountTotalPallets() }} Pallets
          </v-chip> -->

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">thermostat</v-icon>
                <span v-if="booking.regimeCode">{{ booking.regimeCode }}</span>
                <span v-else>No Regime Code</span>
                <v-icon v-if="booking.regime && booking.regime.steri" color="blue" right>ac_unit</v-icon>
              </v-chip>
            </template>
            <span>Regime Code <span v-if="booking.regime && booking.regime.steri" class="ml-1">: STERI</span></span>
          </v-tooltip> -->
          <!-- <v-chip outlined style="border: none">
            <v-icon left :color="'grey'">category</v-icon>
            <span class="ml-1" v-if="productList().length > 0">
              {{ productList() }}</span
            >
            <span v-else>No Products</span>
          </v-chip>
          <v-chip v-if="booking.isHazardous"  outlined
            style="border: none">
          <v-icon color="orange" left>warning</v-icon> Hazardous
          </v-chip> -->
        </v-row>
       


        <!-- <v-row>
          <v-col class="text-center mb-0 pb-0">
            <v-row class="text-center" v-if="shipment.consigneeProfileId">
              <v-col v-if="shipment.shipper">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">outbound</v-icon>
                  </template>
                  <span>Shipper</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ shipment.shipper ? shipment.shipper.name : "-" }}</span>
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col v-if="shipment.forwarder">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">fast_forward</v-icon>
                  </template>
                  <span>Forwarder</span>
                </v-tooltip> <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ shipment.forwarder ? shipment.forwarder.name : "-" }}</span>
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col v-if="shipment.buyer">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">shopping_cart</v-icon>
                  </template>
                  <span>Buyer</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ shipment.buyer ? shipment.buyer.name : "-" }}</span>
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col v-if="shipment.consignee">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">call_received</v-icon>
                  </template>
                  <span>Consignee</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ shipment.consignee ? shipment.consignee.name : "-" }}</span>
              </v-col>
              <v-divider vertical inset></v-divider>
            </v-row>
          </v-col>
          <v-col class="text-center">
            <v-row class="text-center">
              <v-col cols="12" sm="4">
                <v-row align="center" class="mt-0 pt-0">
                  <v-col cols="4" class="mt-0 pt-0 text-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-avatar size="32" right v-on="on">
                          <v-img contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                      </template>
                      <span>{{ shipment.originCountry.name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" class="mt-0 pt-0 text-center">
                    <v-icon>arrow_forward</v-icon>
                  </v-col>
                  <v-col cols="4" class="mt-0 pt-0 text-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-avatar left size="32" v-on="on">
                          <v-img contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                      </template>
                      <span>{{ shipment.destinationCountry.name }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <span style="font-size: 12px; color: var(--v-primaryText-base)">
                  {{ shipment.portOfLoadValue }} -
                  {{ shipment.finalDestinationValue }}</span>
                <br v-if="shipment.portOfDischargeValue && shipment.portOfDischargeValue != shipment.finalDestinationValue" />

                <span style="font-size: 12px; color: var(--v-primaryText-base)"
                  v-if="shipment.portOfDischargeValue && shipment.portOfDischargeValue != shipment.finalDestinationValue">
                  VIA
                  {{ shipment.portOfDischargeValue }}</span>

              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col cols="12" sm="4">
                <div v-if="shipment.shippingLine">
                  <v-avatar class="mb-1" v-if="shipment.shippingLine.logo" color="white">
                  <v-img :src="shipment.shippingLine.logo" contain></v-img>
                  </v-avatar> <br/>
                  {{ shipment.shippingLine.name }}
                </div>
                <div v-else>
                  <v-icon large class="mb-1">directions_boat_filled</v-icon> 
                </div>
                <span v-if="shipment.vessel" style="color: var(--v-primaryText-base)">
                  {{ shipment.vessel.name }}
                </span>
                <span v-else>{{ shipment.vesselName }}</span>  <span> {{ shipment.voyage }}<v-btn @click="editVoyage()"
                    v-if="shipment.forwarderId == $store.state.currentOrg.id" class="ml-1" color="secondary" icon
                    small><v-icon small>edit</v-icon></v-btn></span> 
                    
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col cols="12" sm="4">
                    <v-icon large class="mb-1">schedule</v-icon> <br />
                <span v-if="shipment.isATD" style="font-size: 12px; color: var(--v-success-base)">ATD: {{
                  formatDate(shipment.etd) }}</span>
                <span v-else style="font-size: 12px; color: var(--v-primaryText-base)">ETD: {{ formatDate(shipment.etd)
                }}</span>
                <br />
                <span v-if="shipment.isATA" style="font-size: 12px; color: var(--v-success-base)">ATA: {{
                  formatDate(shipment.eta) }}</span>
                <span v-else style="font-size: 12px; color: var(--v-primaryText-base)">ETA: {{ formatDate(shipment.eta)
                }}</span>
                <br>
                <v-btn @click="viewStackDates()" small outlined class="my-0 py-0" style="text-transform: none" v-if="shipment.voyageInfo && shipment.voyageInfo.rmsStack" color="blue"><v-icon>view_module</v-icon> Stack dates</v-btn>
             </v-col>
             </v-row>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>

    <v-dialog v-model="editVoyageNumber" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Edit Voyage Number

          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="saveVoyage()" icon :loading="saving"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="close()">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field placeholder="Voyage No." v-model="shipment.voyage" outlined dense clearable></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="stackDateModal" width="400px" persistent>
    <v-card>
      <v-toolbar flat color="transparent">
        <v-toolbar-title>Stack Dates</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="stackDateModal = false">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-list-item-action>
            <v-icon>anchor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ stackDates.terminal }}
            </v-list-item-title>
            <v-list-item-subtitle>Planned Loads: {{ stackDates.plannedLoads }}</v-list-item-subtitle>
            <v-list-item-subtitle>Planned Discharges: {{ stackDates.plannedDischarges }}</v-list-item-subtitle>
          </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="amber">widgets</v-icon>Dry Stacks</v-subheader>
          <v-divider></v-divider>

          <v-list-item>
            <!-- <v-list-item-action>
            <v-icon color="blue">schedule</v-icon>
          </v-list-item-action> -->
          <v-list-item-content>
            <v-list-item-title>
              {{ formatDate(stackDates.dryStackStart) }}
            </v-list-item-title>
            <v-list-item-subtitle>Stacks Open</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip small :color="stackDates.dryStackStartConfirmed?'success':'deep-orange'">{{ stackDates.dryStackStartConfirmed?'Firm':'Provisional' }}</v-chip>
          </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <!-- <v-list-item-action>
            <v-icon color="orange">schedule</v-icon>
          </v-list-item-action> -->
          <v-list-item-content>
            <v-list-item-title>
              {{ formatDate(stackDates.dryStackFinish) }}
            </v-list-item-title>
            <v-list-item-subtitle> Stacks Close</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip small :color="stackDates.dryStackFinishConfirmed?'success':'deep-orange'">{{ stackDates.dryStackFinishConfirmed?'Firm':'Provisional' }}</v-chip>
          </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="blue">ac_unit</v-icon>Reefer Stacks</v-subheader>
          <v-divider></v-divider>

          <v-list-item>
            <!-- <v-list-item-action>
            <v-icon color="blue">schedule</v-icon>
          </v-list-item-action> -->
          <v-list-item-content>
            <v-list-item-title>
              {{ formatDate(stackDates.reeferStackStart) }}
            </v-list-item-title>
            <v-list-item-subtitle>Stacks Open</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip small :color="stackDates.reeferStackStartConfirmed?'success':'deep-orange'">{{ stackDates.reeferStackStartConfirmed?'Firm':'Provisional' }}</v-chip>
          </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <!-- <v-list-item-action>
            <v-icon color="orange">schedule</v-icon>
          </v-list-item-action> -->
          <v-list-item-content>
            <v-list-item-title>
              {{ formatDate(stackDates.reeferStackFinish) }}
            </v-list-item-title>
            <v-list-item-subtitle>Stacks Close</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip small :color="stackDates.reeferStackFinishConfirmed?'success':'deep-orange'">{{ stackDates.reeferStackFinishConfirmed?'Firm':'Provisional' }}</v-chip>
          </v-list-item-action>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";

export default {
  props: ["shipment", "relationships"],
  data: () => ({
    editVoyageNumber: false,
    originalVoyageNumber: null,
    saving: false,
    stackDates: {},
    stackDateModal: false
  }),
  methods: {
    close() {
      this.shipment.voyage = this.originalVoyageNumber
      this.editVoyageNumber = false
    },
    editVoyage() {
      if (!this.originalVoyageNumber) {
        this.originalVoyageNumber = this.shipment.voyage
      }
      this.editVoyageNumber = true
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy");
      }
      return result;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    getMainStatusColor(status) {
      switch (status) {
        case "Open":
          return "blue";
        case "Draft":
          return "orange";
        case "Complete":
          return "green";
      }
    },
    async saveVoyage() {
      this.saving = true
      await this.$API.updateShipmentDetail({
        id: this.shipment.id,
        voyage: this.shipment.voyage
      })
      this.editVoyageNumber=false
      this.saving = false
    },
    viewStackDates(){
      this.stackDates = this.shipment.voyageInfo.rmsStack
      this.stackDateModal = true
    }
  },
};
</script>
