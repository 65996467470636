<template>
    <div>
        <div v-if="!loading">
            <v-card flat style="background-color: var(--v-background-base) !important">
                <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                    <v-btn class="mr-3" icon @click="goBack">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="shipment.shipmentFile">
                        <v-chip
                            @click="$store.state.currentOrg.id == shipment.forwarderId ? statusConfirmationDialog = true : null"
                            outlined small :color="getMainStatusColor(shipment.documentationStatus)"
                            class="white--text mr-2">{{
                                shipment.documentationStatus }}</v-chip>

                        <v-tooltip bottom v-if="shipment && !shipment.breakBulkShipment && shipment.sealMismatch">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-if="shipment && !shipment.breakBulkShipment && shipment.sealMismatch"
                                    v-bind="attrs" v-on="on">
                                    <v-icon color="red">warning</v-icon>
                                </v-btn>
                            </template>
                            <span>Seal Discrepancy</span>
                        </v-tooltip>

                        <b class="mr-3">{{ shipment.shipmentFile.fileNumber }}</b>

                        <v-chip v-if="shipment && shipment.breakBulkShipment" color="blue" outlined
                            class="mx-1">Breakbulk</v-chip>
                    </v-toolbar-title>
                    <v-chip outlined style="border: none" class="ml-2" :key="routeKey">
                        <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-chip pill small outlined class="primaryText--text">
                                        <v-avatar size="24" left>
                                            <v-img contain v-if="location.locode" :src="`https://cdn.loglive.io/flags/4x3/${location.locode
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"></v-img> </v-avatar>{{ location.locode }}
                                    </v-chip>

                                    <v-icon class="mx-3" color="grey" small
                                        v-if="index < fullRoute.length - 1">east</v-icon>
                                </div>
                            </template>
                            <span style="font-size: 12px">{{ location.type }}: {{ location.locode }}</span>
                        </v-tooltip>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <div v-if="!$vuetify.breakpoint.mobile" :key="userUpdateKey" class="mr-2">
                        <v-tooltip top v-for="user in activeUsers" :key="user.connectionId">
                            <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                                    class="mt-0 pt-0 mx-1">
                                    <span v-if="!user.user.avatar"
                                        class="primaryText--text text-h5">{{ $Format.avatarAbbreviation(user.user.name)
                                        }}</span>
                                    <img v-else :src="user.user.avatar" referrerpolicy="no-referrer" />
                                </v-avatar>
                            </template>
                            <span style="font-size: 12px">{{ user.user.name }}</span>
                        </v-tooltip>
                    </div>
                </v-toolbar>

                <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                    'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                    'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
                }">
                    <v-card-text :style="{
                        height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                        'overflow-y': 'auto',
                    }" class="my-0 py-0">
                        <Header class="ma-0 pa-0" :shipment="shipment" :relationships="relationships" />
                        <v-card flat class="ma-0 pa-0">
                            <v-tabs v-model="tabValue" class="my-0 py-0" show-arrows
                                style="background-color: var(--v-background-base)">
                                <v-tab shaped v-for="tab in tabs" :key="tab.value" :href="`#${tab.value}`"
                                    @click="setActiveTab(tab.value)">{{ tab.name }}</v-tab>
                            </v-tabs>
                            <v-card-text style="max-height: 78vh; overflow-y: auto">
                                <!-- Overview tab -->
                                <v-tabs-items v-model="tabValue"
                                    style="background-color: var(--v-background-base) !important">
                                    <v-tab-item value="Overview"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <Overview :relationships="relationships" :shipment="shipment"
                                                    :documents="documentTypes" :customerId="customerId" :events="events"
                                                    :shipmentProducts="shipmentProducts" :uploads="documents"
                                                    :tasks="tasks" @refreshTasks="refreshTasks"
                                                    @updateEvent="updateEvent" @refreshEvents="refreshEvents"
                                                    @refresh="refreshShipment()" />
                                            </v-card-text>
                                        </v-card>

                                    </v-tab-item>

                                    <v-tab-item value="Shipment Overview"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <ShipmentOverview :shipment="shipment" :bookings="bookings"
                                                    :documents="documentTypes" :shipmentChangeLogs="shipmentChangeLogs"
                                                    :customerId="customerId" :loadingProducts="loadingProducts"
                                                    :shipmentProducts="shipmentProducts" :relationships="relationships"
                                                    @refresh="refreshShipment()" />
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item value="Containers"
                                        style="background-color: var(--v-background-base) !important">
                                        <BookingContainer :key="bookingKey" :shipment="shipment" :bookings="bookings"
                                            :type="type" :shipmentProducts="shipmentProducts"
                                            @refreshContainers="refreshBookings()" />
                                    </v-tab-item>
                                    <v-tab-item value="MinContainers"
                                        style="background-color: var(--v-background-base) !important">
                                        <DefaultContainer :shipment="shipment" :bookings="bookings" :scope="scope"
                                            :shipmentProducts="shipmentProducts" />
                                    </v-tab-item>
                                    <v-tab-item value="ProductBreakdown"
                                        style="background-color: var(--v-background-base) !important">
                                        <ProductBreakdown :shipment="shipment" :scope="scope"
                                            :shipmentProducts="shipmentProducts" />
                                    </v-tab-item>

                                    <v-tab-item value="Pallets"
                                        style="background-color: var(--v-background-base) !important">
                                        <ShipmentPallet :item="shipment" :pallets="pallets" />
                                    </v-tab-item>

                                    <v-tab-item value="Documents"
                                        style="background-color: var(--v-background-base) !important">
                                        <Documents :actions="actions" :bookings="bookings" :documents="documentTypes"
                                            :item="shipment" :loadingDocuments="loadingDocuments" :uploads="documents"
                                            :tasks="tasks" :taskUpdateKey="taskUpdateKey" :relationships="relationships"
                                            :documentUpdateKey="documentTypeUpdateKey" @updateDocument="updateDocument"
                                            @update="updateTask" @refreshDocuments="refreshDocuments"
                                            @refreshTasks="refreshTasks" @getShipmentDocuments="getShipmentDocuments"
                                            @refreshActions="refreshActions" :userScope="userScope" :events="events"
                                            :shipment="shipment" :documentTypes="documentTypes"
                                            :fileNumber="shipment.shipmentFile.fileNumber" />
                                    </v-tab-item>

                                    <v-tab-item value="Uploaded Documents"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <DocumentUploads :bookings="bookings"
                                                    :documentUpdateKey="documentUpdateKey" :item="shipment"
                                                    :documentTypes="documentTypes" :documents="documents"
                                                    @getShipmentDocuments="getShipmentDocuments" />
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <v-tab-item value="Uploads"
                                        style="background-color: var(--v-background-base) !important">
                                        <DefaultUpload :shipment="shipment" :bookings="bookings"
                                            :documentTypes="documentTypes" :documents="documents"
                                            @getShipmentDocuments="getShipmentDocuments" />
                                    </v-tab-item>

                                    <v-tab-item value="Shipment Profile"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <ConsigneeProfileView :shipment="shipment" :userScope="userScope" />
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item value="Actions"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <Task @updateDocument="updateDocument" @update="updateTask"
                                                    :tasks="activeTasks" :events="events" :bookings="bookings"
                                                    :uploads="documents" :shipment="shipment"
                                                    :taskUpdateKey="taskUpdateKey" :actions="actions"
                                                    :documentTypes="documentTypes" :documents="documents"
                                                    :documentUpdateKey="documentUpdateKey" />
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item value="Vessel Tracking"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <VesselTracking :voyage="vesselTracking" />
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <v-tab-item value="Documentation"
                                        style="background-color: var(--v-background-base) !important">
                                        <v-card flat>
                                            <v-card-text>
                                                <Documentation />
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>



                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-card>

            <v-dialog v-model="statusConfirmationDialog" persistent width="400px"
                :fullscreen="$vuetify.breakpoint.mobile">
                <v-card :loading="statusLoading">
                    <v-toolbar flat>
                        <v-card-title>Change File Status</v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn text flat @click="statusConfirmationDialog = false">X</v-btn>
                    </v-toolbar>
                    <v-card-text class="text-center">
                        <v-row justify="center" class="mt-3 mb-3">
                            <v-btn color="info" class="mx-2" outlined small
                                @click="processStatusChange('Open')">Open</v-btn>
                            <v-btn color="warning" class="mx-2" outlined small
                                @click="processStatusChange('Cancelled')">Cancelled</v-btn>
                            <v-btn color="red" class="mx-2" outlined small
                                @click="processStatusChange('Closed')">Closed</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="onHoldWarningModal" width="400px" persistent>
                <v-card>
                    <v-card-text>
                        <v-row class="mt-3 pt-5">
                            <v-col cols="12" class="text-center">
                                <v-icon x-large color="orange">warning
                                </v-icon>
                            </v-col>
                            <v-col cols="12">
                                <p>The following customer accounts are on hold:</p>
                                <ul>
                                    <li v-for="relationship in relationships.filter(x => x.organisationRelationship.clientStatus == 'ON HOLD')"
                                        :key="relationship.id">
                                        {{ relationship.customer.name }}
                                    </li>
                                </ul>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-row justify="center" class="mt-2 mb-3">
                                    <v-btn color="success" class="mx-2" text small
                                        @click="onHoldWarningModal = false">Accept</v-btn>
                                    <v-btn color="red" class="mx-2" text small @click="$router.go(-1)">Reject</v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <div v-else style="
                height: 100%;
                padding-top: 0;
                margin-top: 0;
                background: transparent;
              " class="text-center">
            <valhalla-loading />
        </div>
    </div>
</template>
<script>
import ConsigneeProfileView from "./ConsigneeProfileView.vue";
import Header from "./Header.vue";
import BookingContainer from "../Shipments/BookingContainers.vue";
import DefaultContainer from './DefaultContainers.vue'
import DefaultUpload from './DefaultUploads.vue'
import Documentation from '../Documentation/Documentation.vue'
import Documents from "./Document.vue";
import DocumentUploads from "./DocumentUpload.vue";
import * as moment from "moment";
import Overview from "./Overview.vue";
import ProductBreakdown from './ProductBreakdown.vue'
import ShipmentOverview from "./ShipmentOverview.vue";
import ShipmentPallet from "./ShipmentPallet.vue";
import Task from "./Task.vue";
import VesselTracking from './VesselTracking.vue'

export default {
    components: {
        BookingContainer,
        ConsigneeProfileView,
        DefaultContainer,
        DefaultUpload,
        Documentation,
        Documents,
        DocumentUploads,
        Header,
        Overview,
        ProductBreakdown,
        ShipmentOverview,
        ShipmentPallet,
        Task,
        VesselTracking
    },
    data: () => ({
        type: 'Docs',
        actions: [],
        activeUsers: [],
        activeTasks: [],
        bookings: [],
        bookingKey: 250,
        customerId: null,
        documents: [],
        documentUpdateKey: 0,
        documentTypes: [],
        documentTypeUpdateKey: 0,
        events: [],
        eventsLoading: false,
        loading: false,
        loadingDocuments: false,
        loadingProducts: false,
        onHoldWarningModal: false,
        message: null,
        pallets: [],
        scope: null,
        shipment: {},
        shipmentProducts: [],
        tab: 0,
        orginate: null,
        tabs: [
            {
                name: 'Overview',
                value: 'Overview'
            },
            {
                name: 'Shipment Overview',
                value: 'Shipment Overview'
            },
            {
                name: 'Containers',
                value: 'Containers'
            },
            {
                name: 'Documents',
                value: 'Documents'
            },
            {
                name: 'Uploaded Documents',
                value: 'Uploaded Documents'
            },
            {
                name: 'Tasks & Actions',
                value: 'Actions'
            },
            {
                name: 'Shipment Profile',
                value: 'Shipment Profile'
            },
            {
                name: 'Documentation (new)',
                value: 'Documentation'
            }
        ],
        relationships: [],
        routeKey: 500,
        shipmentChangeLogs: [],
        statusConfirmationDialog: false,
        statusLoading: false,
        tabValue: 'Overview',
        tasks: [],
        taskUpdateKey: 1000,
        userUpdateKey: 0,
        userScope: null,
        vesselTracking: null,
        customEvents: [],
    }),
    watch: {
        "$route.params.id": {
            immediate: true,
            handler(value) {
                if (value) {
                    this.load(value);

                }
            }
        },
        'shipment.breakBulkShipment': {
            immediate: true,
            handler(val) {
                if (val) {
                    let index = this.tabs.findIndex(x => x.value == 'Containers')
                    if (index == -1) {
                        index = this.tabs.findIndex(x => x.value == 'MinContainers')

                    }
                    if (index > -1) {
                        this.tabs[index] = {
                            name: 'Breakbulk Pallets',
                            value: 'Containers'
                        }
                    }

                }
            }
        }
    },
    async mounted() {
        this.tabValue = sessionStorage.getItem('activeTab') || 'Overview'

        // Shipments Custom Events
       

        // let orginate = sessionStorage.getItem('originate')
        // // console.log('originate',orginate)
        // if(orginate){
        //   this.orginate = orginate
        // }

        // let schedules = await this.$API.getSailingSchedule({
        //   params: {
        //   scac: 'MSCU',
        //   fromPort: 'ZACPT',
        //   toPort: 'RULED',
        //   fromDate: '2023-05-25',
        //   toDate: '2023-06-15',
        // }})
        // console.log(schedules)
        // this.$socket.send(
        //     JSON.stringify({
        //       action: "join",
        //       room: "shipment_" + this.shipment.id,
        //       user: {
        //         id: this.$store.state.user.id,
        //         name:
        //           this.$store.state.user.firstname +
        //           " " +
        //           this.$store.state.user.surname,
        //         avatar: this.$store.state.user.avatar,
        //         company: this.$store.state.currentOrg.name,
        //       },
        //     })
        //   );

    },
    unmounted() {
        this.$socket.send(
            JSON.stringify({
                action: "leave",
                room: "shipment_" + this.shipment.id,
                user: {
                    id: this.$store.state.user.id,
                    name:
                        this.$store.state.user.firstname +
                        " " +
                        this.$store.state.user.surname,
                    avatar: this.$store.state.user.avatar,
                    company: this.$store.state.currentOrg.name,
                },
            })
        );
        delete this.$socket.onmessage;
    },
    beforeDestroy() {
        this.$socket.send(
            JSON.stringify({
                action: "leave",
                room: "shipment_" + this.shipment.id
                // user: {
                //   id: this.$store.state.user.id,
                //   name:
                //     this.$store.state.user.firstname +
                //     " " +
                //     this.$store.state.user.surname,
                //   avatar: this.$store.state.user.avatar,
                //   company: this.$store.state.currentOrg.name,
                // },
            })
        );
        // console.log('Leave action triggered')
        // delete this.$socket.onmessage;
    },
    // async mounted(){

    //   console.log(schedules)
    // },
    computed: {
        fullRoute() {
            //   console.log('booking', this.shipment)
            let result = [];
            let setTypes = [
                { type: "Port of Load", locode: this.shipment.portOfLoadValue },
                {
                    type: "Port of Discharge",
                    locode: this.shipment.portOfDischargeValue,
                },
                {
                    type: "Final Destination",
                    locode: this.shipment.finalDestinationValue,
                },
            ];

            // POL
            result.push(setTypes[0])

            // Transhipments
            if (this.shipment.sailingSchedule && this.shipment.sailingSchedule.sailingScheduleLegs.length > 1) {
                const findLeg = this.shipment.sailingSchedule.sailingScheduleLegs.find(x => x.leg === 1)
                if (findLeg && findLeg.portOfDischargeValue) {
                    result.push({ type: "TS Port 1", locode: findLeg.portOfDischargeValue });
                }
            }

            // POD & FD
            if (this.shipment.portOfDischargeValue === this.shipment.finalDestinationValue) {
                result.push({
                    type: "POD & Final Destination",
                    locode: this.shipment.finalDestinationValue
                })
            } else {
                result.push(setTypes[1])
                result.push(setTypes[2])
            }
            return result;
        },
    },
    methods: {
        goBack() {
            sessionStorage.removeItem('activeTab');
            this.$router.go(-1);
        },
        setActiveTab(tab) {
            this.tabValue = tab;
            sessionStorage.setItem('activeTab', tab);
        },
        addDocument(document) {
            this.documents.push(document);
            this.documentUpdateKey++;
        },

        calculateDays(start, end) {
            start = moment(start);
            end = moment(end);
            let difference = end.diff(start, 'days');
            let count = difference;
            return count + 1;
        },
        getMainStatusColor(status) {
            switch (status) {
                case "OPEN":
                    return "info";
                case "CANCELLED":
                    return "warning";
                case "CLOSED":
                    return "red";
            }
        },
        async handleMessage(message) {
            if (typeof message == "string") {
                message = JSON.parse(message);
            }
            if (message.type == "update_document" &&
                message.room == "shipment_" + this.shipment.id) {
                let document = await this.$API.getShipmentDocumentDetail(message.id);
                let findIndex = this.documents.findIndex(
                    (doc) => doc.id == document.id
                );
                if (findIndex > -1) {
                    this.documents[findIndex] = document;
                } else {
                    this.documents.push(document);
                }
                this.documentUpdateKey++;
            } else if (message.type == "document_update" &&
                message.room == "shipment_" + this.shipment.id) {
                let updatedDocument = await this.$API.getShipmentDocumentTypeDetail(
                    message.id
                );
                // console.log(
                //   "Document update",
                //   message.id,
                //   updatedDocument.locationDocument.name,
                //   updatedDocument.integrationStatus,
                //   updatedDocument.status
                // );
                // }
                if (message.parentShipmentDocumentId &&
                    message.parentShipmentDocumentId != "null") {
                    let findTask = this.documentTypes.findIndex(
                        (x) => x.id == message.parentShipmentDocumentId
                    );
                    if (findTask > -1) {
                        let childTask = this.documentTypes[findTask].variations.findIndex(
                            (x) => x.id == message.id
                        );
                        if (childTask > -1) {
                            this.documentTypes[findTask].variations[childTask] = {
                                ...this.documentTypes[findTask].variations[childTask],
                                ...updatedDocument,
                            };
                        }
                    }
                } else {
                    let findTask = this.documentTypes.findIndex(
                        (x) => x.id == message.id
                    );
                    if (findTask > -1) {
                        // console.log('Updating Document: Landing', this.documentTypes[findTask])
                        this.documentTypes[findTask] = {
                            ...this.documentTypes[findTask],
                            ...updatedDocument,
                        };
                    }
                }
                this.documentTypeUpdateKey++;
            } else if (message.type == "task_update" &&
                message.room == "shipment_" + this.shipment.id) {
                // if(message.data.taskAttachments){
                // console.log('Attachment update', message.data)
                // //   message.data.taskAttachments = JSON.parse(message.data.taskAttachments)
                // }
                if (message.parentTaskId) {
                    let findTask = this.tasks.findIndex(
                        (x) => x.id == message.parentTaskId
                    );
                    if (findTask > -1) {
                        let childTask = this.tasks[findTask].childTasks.findIndex(
                            (x) => x.id == message.id
                        );
                        if (childTask > -1) {
                            if (message.data) {
                                if (typeof message.data == "string") {
                                    message.data = JSON.parse(message.data);
                                }
                                this.tasks[findTask].childTasks[childTask] = {
                                    ...this.tasks[findTask].childTasks[childTask],
                                    ...message.data,
                                };
                            }
                        }
                    }
                } else {
                    let findTask = this.tasks.findIndex((x) => x.id == message.id);
                    if (findTask > -1) {
                        if (message.data) {
                            if (typeof message.data == "string") {
                                message.data = JSON.parse(message.data);
                            }
                            this.tasks[findTask] = {
                                ...this.tasks[findTask],
                                ...message.data,
                            };
                        }
                    }
                }
                // console.log("Task update", message.id, message.data);
                this.documentTypeUpdateKey++;
                this.taskUpdateKey++;
                this.activeTasks = this.tasks.filter((x) => x.isActive);
            }




















            // else if (
            //   message.type == "new_task_document" &&
            //   message.room == "shipment_" + this.$route.params.id
            // ) {
            //   let findParentTask = this.tasks.findIndex((x) => x.id == message.id);
            //   if (findParentTask) {
            //     if (message.data) {
            //       if (typeof message.data == "string") {
            //         message.data = JSON.parse(message.data);
            //       }
            //       let findAttachment = this.tasks[
            //         findParentTask
            //       ].taskAttachments.findIndex((x) => x.id == message.data.id);
            //       if (findAttachment == -1) {
            //         this.tasks[findParentTask].taskAttachments.push(message.data);
            //         this.taskUpdateKey++;
            //       }
            //     }
            //   }
            // } 
            else if (message.type == "task_creation" &&
                message.room == "shipment_" + this.shipment.id) {
                if (message.data) {
                    if (typeof message.data == "string") {
                        message.data = JSON.parse(message.data);
                    }
                    this.shipment = { ...this.shipment, ...message.data };
                    if (message.data.taskStatus == "Complete") {
                        this.tasks = await this.$API.getShipmentTasks(this.shipment.id);
                        this.events = await this.$API.getShipmentEvents(this.shipment.id);

                        this.activeTasks = this.tasks.filter((x) => x.isActive);
                        this.documentTypes = await this.$API.getShipmentDocumentTypes(
                            this.shipment.id
                        );
                        this.documentTypeUpdateKey++;
                    }
                }
                // console.log("task_creation", message.data);
            } else if (message.type == "document_type_creation" &&
                message.room == "shipment_" + this.shipment.id) {
                if (message.data) {
                    if (typeof message.data == "string") {
                        message.data = JSON.parse(message.data);
                    }
                    this.shipment = { ...this.shipment, ...message.data };
                    if (message.data.fileStatus == "Complete") {
                        this.getShipmentProducts();
                        this.refreshBookings();
                        this.documentTypes = await this.$API.getShipmentDocumentTypes(
                            this.shipment.id
                        );
                        this.actions = await this.$API.getShipmentActions(this.shipment.id);
                        this.documentTypeUpdateKey++;
                    }
                }
                // console.log("document_type_creation", message.data);
            }
        },
        async getShipmentProducts() {
            this.loadingProducts = true;
            this.shipmentProducts = await this.$API.getShipmentProducts(
                this.shipment.id
            );
            this.loadingProducts = false;
        },
        async refreshBookings() {
            this.bookings = await this.$API.getShipmentBookings(
                this.shipment.shipmentFileId
            );
            this.bookingKey++;
        },
        async refreshTasks() {
            this.tasks = await this.$API.getShipmentTasks(this.shipment.id);
            this.activeTasks = this.tasks.filter((x) => x.isActive);
        },
        async getVesselDetails() {
            // console.log("Test remove voyage get")
            this.shipment.voyageInfo = await this.$API.getShipmentVesselVoyage(
                this.shipment.shipmentReference
            );
            if (this.shipment.voyageInfo) {
                if (new Date(this.shipment.voyageInfo.etd) < new Date()) {
                    let difference = this.calculateDays(new Date(this.shipment.etd), new Date());
                    let shipmentTotal = this.calculateDays(new Date(this.shipment.etd), new Date(this.shipment.eta));
                    difference = difference > shipmentTotal ? shipmentTotal : difference;
                    this.shipment.voyageInfo.progress = difference > 0 ? difference : 0;
                } else {
                    this.shipment.voyageInfo.progress = 0;
                }
                this.shipment.voyageInfo.vesselVoyageLegs.forEach(x => {
                    let shipmentTotal = this.calculateDays(new Date(x.etd), new Date(x.eta));
                    let difference = this.calculateDays(new Date(x.etd), new Date());
                    difference = difference > shipmentTotal ? shipmentTotal : difference;
                    if (new Date(x.etd) < new Date()) {
                        x.progress = difference > 0 ? difference : 0;
                    } else {
                        x.progress = 0;
                    }
                    // console.log('Progess', x.id, x.progress)
                });
            }
            // if (this.shipment.vessel) {
            //   this.shipment.vesselInfo = await this.$API.getVesselDetail(
            //     this.shipment.vesselId
            //   );
            // }
        },
        async getShipmentRelationships(id) {
            this.relationships = await this.$API.getShipmentRelationships(id);
            this.onHoldWarningModal = this.relationships.some(x => x.organisationRelationship.clientStatus == 'ON HOLD');
            if (this.relationships.length > 0) {
                this.customerId = this.relationships[0].customerId;
            }
        },
        async setAccessState() {
            let access = await this.$API.getUserScope({ path: '/documentation', module: 'Shipments' });
            this.userScope = access;
            await this.$store.dispatch("setAccessState", access);
        },
        async load(id) {
            this.loading = true;
            await this.setAccessState();
            this.scope = await this.$API.authoriseScope({
                type: "shipment"
            });
            if (typeof this.scope.tabs == 'string') {
                this.scope.tabs = JSON.parse(this.scope.tabs);
            }
            this.tabs = this.scope.tabs;
            if (this.tabs.length > 0) {
                this.activeTab = this.tabs[0].value;
            }
            // this.tabs.push({
            //     name: 'Documentation (new)',
            //     value: 'Documentation'
            // })
            this.shipment = await this.$API.getSingleShipment(id);
            //   console.log('shipment', this.documentTypes);
            this.getShipmentRelationships(this.shipment.id);
            this.getShipmentChangeLog(this.shipment.id);
            this.getVesselDetails();
            this.customEvents = await this.$API.getShipmentCustomEvents(this.shipment.id)
            setTimeout(() => {
                this.$socket.send(
                    JSON.stringify({
                        action: "join",
                        room: "shipment_" + this.shipment.id,
                        user: {
                            id: this.$store.state.user.id,
                            name: this.$store.state.user.firstname +
                                " " +
                                this.$store.state.user.surname,
                            avatar: this.$store.state.user.avatar,
                            company: this.$store.state.currentOrg.name,
                        },
                    })
                );
            }, 1000);
            this.$socket.onopen = () => {
                this.$socket.send(
                    JSON.stringify({
                        action: "join",
                        room: "shipment_" + this.shipment.id,
                        user: {
                            id: this.$store.state.user.id,
                            name: this.$store.state.user.firstname +
                                " " +
                                this.$store.state.user.surname,
                            avatar: this.$store.state.user.avatar,
                            company: this.$store.state.currentOrg.name,
                        },
                    })
                );
            };
            this.$socket.onmessage = (data) => {
                let message = JSON.parse(data.data);
                // console.log('Message received', message)
                if (this.shipment && this.shipment.id) {

                    this.handleMessage(message);
                    if (message.type == "users" &&
                        message.room == "shipment_" + this.shipment.id) {
                        this.activeUsers = message.data;
                        this.userUpdateKey++;
                    }
                }

            };
            //   this.summaryItems = await this.shipmentSummaryItems();
            // console.log(this.summaryItems)
            //   await this.getDocuments();
            if (this.scope.type == 'paid' || this.scope.type == 'trial') {
                this.actions = await this.$API.getShipmentActions(this.shipment.id);

                this.events = await this.$API.getShipmentEvents(this.shipment.id);
                this.getShipmentDocuments();
                this.tasks = await this.$API.getShipmentTasks(this.shipment.id);
                this.activeTasks = this.tasks.filter((x) => x.isActive);

            }
            if (this.shipment.vesselVoyageId) {
                this.vesselTracking = await this.$API.getVesselVoyageTracking(this.shipment.vesselVoyageId);
                // console.log(this.vesselTracking)
                if (this.vesselTracking && this.vesselTracking && this.vesselTracking.routeHistory && this.vesselTracking.routeHistory.length > 0) {
                    this.tabs.push({
                        value: 'Vessel Tracking',
                        name: 'Vessel Tracking'
                    });
                }

            }

            await this.getShipmentProducts();
            this.bookings = await this.$API.getShipmentBookings(
                this.shipment.shipmentFileId
            );
            this.pallets = [].concat.apply(
                [],
                this.bookings.map((x) => x.containerPallets)
            );
            this.documentTypes = await this.$API.getShipmentDocumentTypes(
                this.shipment.id
            );

            this.loading = false;
        },
        async getShipmentChangeLog(id) {
            this.shipmentChangeLogs = await this.$API.getShipmentChanges(id);
        },
        async processStatusChange(status) {
            this.statusLoading = true;

            this.$confirm(`Are you sure you want to change the shipment status to ${status}?`, 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                if (status === 'Closed') {
                    let eventDocuments= this.events.filter(x=>x.shipmentEventLinks && x.shipmentEventLinks.length>0)
                    eventDocuments = [].concat.apply([], eventDocuments.map(x=>x.shipmentEventLinks))
                    eventDocuments= eventDocuments.map(x=>x.shipmentDocumentTypeId)
                    let filterDocuments = this.documentTypes.filter(x => x.status != 'Complete' && x.status != 'Not Required' && !eventDocuments.includes(x.id));
                    if(filterDocuments.length > 0){
                        this.$message.error('ERROR: Not All Documents Are Completed');
                        this.statusConfirmationDialog = false;
                        this.statusLoading = false;
                        return;
                    }
                    let filterEvents = filterDocuments.filter(x => eventDocuments.includes(x.id));
                    let acceptableStatus =['Submitted', 'Complete', 'Not required']
                    for(let i = 0; i < filterEvents.length; i++){
                        const eventStatus = acceptableStatus.includes(filterEvents[i].status)
                        if(!eventStatus){
                            this.$message.error('ERROR: Not All Events Have Been Started');
                            this.statusConfirmationDialog = false;
                            this.statusLoading = false;
                            return;
                        }
                    }
                    for (let i = 0; i < this.customEvents.length; i++) {
                        const eventStatus = this.customEvents[i].status;
                        if (eventStatus != 'Complete' && eventStatus != 'Completed') {
                            this.$message.error(`ERROR: Not All Custom Events Are Completed`);
                            this.statusConfirmationDialog = false;
                            this.statusLoading = false;
                            return;
                        }
                    }
                } 

                    this.shipment.documentationStatus = status.toUpperCase();
                    this.shipment.status = status.toUpperCase();
                    await this.$API.updateShipmentFileStatus(this.shipment.id, { documentationStatus: this.shipment.documentationStatus });
                    this.$message.success('Successfully updated documentation status!');
                    this.statusConfirmationDialog = false;
                    this.statusLoading = false;
            }).catch(() => {
                this.statusConfirmationDialog = false;
                this.statusLoading = false;
            });
        },
        async getShipmentDocuments() {
            this.documents = await this.$API.getShipmentDocuments(this.shipment.id);
            this.documentUpdateKey++;
            // console.log('Refresh result', result)
        },
        async refreshActions() {
            this.actions = await this.$API.getShipmentActions(this.shipment.id);
        },
        async refreshEvents() {
            this.eventsLoading = true;
            this.events = await this.$API.getShipmentEvents(this.shipment.id);
            this.eventsLoading = false;
        },
        async refreshDocuments() {
            this.loadingDocuments = true;
            this.documentTypes = await this.$API.getShipmentDocumentTypes(
                this.shipment.id
            );
            this.loadingDocuments = false;
            // this.tasks = await this.$API.getShipmentTasks(this.shipment.id);
            // this.activeTasks = this.tasks.filter((x) => x.isActive);
        },
        refreshShipment() {
            this.load(this.shipment.shipmentReference);
        },
        updateDocument(doc) {
            let find = this.documentTypes.findIndex(x => x.id == doc.id);
            if (find > -1) {
                this.documentTypes[find] = doc;
            }
        },
        updateTask(task) {
            let find = this.tasks.findIndex(x => x.id == task.id);
            if (find > -1) {
                this.tasks[find] = task;
                // console.log('Task Update received', find, task)
                this.taskUpdateKey++;
            }
        },
        updateEvent(event) {
            let find = this.events.findIndex(x => x.id == event.id);
            this.events[find] = event;
        }
    },
};
</script>
